<template>
    <div class="row">
        <div class="col-md-12">
            <br>
            <h3 style="width: 75%; margin-left:auto;
margin-right:auto;"><input type='text' class="form-control" placeholder="Search..." v-model="search"/></h3>
            <div class="form-group" style="float:right; margin-right: 20px">
                <label>Availability</label>
                <select class=form-control v-model="brandfilter">
                    <option value="All Caskets">All Caskets</option>
                    <option value="Available">Available</option>
                </select>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Color</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="casket in filteredSearch2" :key="casket.key">
                        <td v-if="casket.available == true">{{ casket.name }}</td>
                        <td v-if="casket.available == false" style="background-color: red; color: white">{{ casket.name }}</td>
                        <td>{{ casket.color }}</td>
                        <td><div class="form-group">
                        <label>Quantity</label>
                    <input type="number" class="form-control" v-model="casket.amount" required>
                </div></td>
                    </tr>
                </tbody>
            </table>
            <div style="text-align: center; padding: 20px">
                <button @click="updateCaskets()" class="btn btn-primary btn-block">Save Changes</button>
                <button @click="$router.push('casketlist')" class="btn btn-danger btn-block">Discard Changes</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Caskets: [],
                originalCaskets: [],
                currentTenant: "",
                service: {},
                serviceid: "",
                newCem: {},
                brandfilter: "Available",
                search: "",
                timesLooped: 0,
            }
        },
        computed: {
            filteredSearch:function() {
                return this.Caskets.filter((casket) => {
                    if(this.brandfilter == "All Caskets") {
                        var filterName = casket.name + casket.color;
                        return filterName.toLowerCase().match(this.search.toLowerCase());
                    }
                    if(this.brandfilter == "Available") {
                        var theService = String(casket.available);
                        //var filterName2 = casket.name + casket.color;
                        return theService.match("true")//+filterName2.toLowerCase().match(this.search.toLowerCase());
                    }
                })
            },
            filteredSearch2:function() {
                return this.filteredSearch.filter((casket) => {
                    var theCasket = casket.name;
                    return theCasket.toLowerCase().match(this.search.toLowerCase());
                })
            }
        },
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        methods: {
            async updateCaskets() {
                db.collection('caskets').orderBy('color').orderBy('name').onSnapshot((snapshotChange) => {
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant && doc.data().deleted !== true && this.timesLooped < this.Caskets.length) {
                        console.log("1", doc.data().name, " ", doc.data().amount)
                        var newCasket = this.Caskets[this.Caskets.map((el) => el.name).indexOf(doc.data().name)]
                        if(newCasket.notes == null) {
                            newCasket.notes = ""
                        }
                        if(this.Caskets[this.Caskets.map((el) => el.name).indexOf(doc.data().name)].amount !== this.originalCaskets[this.originalCaskets.map((el) => el.name).indexOf(doc.data().name)].amount) {
                            db.collection('caskets').doc(doc.id)
                            .update(newCasket).then(() => {
                                console.log("2", doc.data().name, " ", newCasket.amount)
                            }).catch((error) => {
                                console.log(error);
                            });
                        }
                        this.timesLooped += 1
                        console.log(this.timesLooped)
                    }
                })
                })
                await new Promise(resolve => setTimeout(resolve, 2000));
                this.$router.push('/casketlist')
                
            },
            updateservices() {
                db.collection('caskets').orderBy('color').orderBy('name').onSnapshot((snapshotChange) => {
                this.Caskets = [];
                this.originalCaskets = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant && doc.data().deleted !== true) {
                    if(doc.data().amount <= 0) {
                        var newCasket = doc.data();
                        newCasket.available = false;
                        db.collection('caskets').doc(doc.id).update(newCasket);
                    }
                    if(doc.data().amount >= 1 && doc.data().available == false) {
                        var newCasket2 = doc.data();
                        newCasket2.available = true;
                        db.collection('caskets').doc(doc.id).update(newCasket2);
                    }
                    this.Caskets.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        available: doc.data().available,
                        color: doc.data().color,
                        amount: doc.data().amount,
                    })
                    this.originalCaskets.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        available: doc.data().available,
                        color: doc.data().color,
                        amount: doc.data().amount,
                    })
                    }
                });
            })
            },
            deleteCasket(id){
              if (window.confirm("Do you really want to delete?")) {
                this.newCem = this.Caskets.find(x => x.key === id)
                console.log(this.newCem)
                this.newCem.deleted = true;
                if(this.newCem.notes == null) {
                    this.newCem.notes = ""
                }
                db.collection("caskets").doc(id).update(this.newCem).then(() => {
                    console.log("Cemetery deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .false {
        background-color: red;
    }
    .btn-primary {
        margin-right: 12px;
    }
</style>